import { useEffect, useState } from "react";
import PayuImg from "../images/Project2.png";
import AirtelImg from "../images/e2eSol_airtel.png";
import PayUCaseStudy from "./file/payU_case_study.pdf";
import { Waypoint } from "react-waypoint";
import Tilt from "react-vanilla-tilt";
import "aos/dist/aos.css";
import Aos from "aos";

const EndToEndSol = () => {
  const isMobile = window.innerWidth <= 500;
  const [style, setStyle] = useState("invisible");
  const [style2, setStyle2] = useState("opacityhigh");
  const [style5, setStyle5] = useState("k");
  const [style6, setStyle6] = useState("noHoverBg");
  const timer = () => {
    setStyle5("animated");
  };
  const end = () => {
    setStyle5("animated2");
  };
  const timer2 = () => {
  };
  const end2 = () => {
  };
  const hover = () => {
    if (!isMobile) {
      setStyle("hoverbutton");
      setStyle2("opacitylow");
      setStyle6("hoverBg");
    }
  };
  const hout = () => {
    if (!isMobile) {
      setStyle("invisible");
      setStyle2("opacityhigh");
      setStyle6("noHoverBg");
    }
  };
  const check = () => {
    if (isMobile) {
      if (style === "hoverbutton") {
        setStyle("invisible");
        setStyle2("opacityhigh");
      } else {
        setStyle("hoverbutton");
        setStyle2("opacitylow");
      }
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  
  return (
    <div className="containner">
      <div className="box1" data-aos="fade-up" data-aos-delay="500">
        <div style={{ position: "relative" }}>
          <Waypoint onEnter={timer} onLeave={end} />
          <div className={style6}>
            <Tilt style={{ width: "100%" }} options={{ scale: 2, max: 15 }}>
              <div className="hover">
                <img
                  onClick={check}
                  onMouseEnter={hover}
                  onMouseLeave={hout}
                  onTouchMove={hover}
                  onTouchStart={hover}
                  onTouchEnd={hout}
                  className={style2}
                  src={AirtelImg}
                  alt="Airtel Project"
                />
              </div>
            </Tilt>
          </div>
          <div
            className={style}
            onClick={check}
            onMouseEnter={hover}
            onMouseLeave={hout}
            onTouchMove={hover}
            onTouchStart={hover}
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
            }}
          >
            <div className="hoverTopTxt">
              <h1>RESTRICTED ACCESS</h1>
              <p>Due to Non-disclosure Agreement (NDA)</p>
            </div>

            <div className="hoverbtn">
              <a
                href="https://drive.google.com/file/d/1yJReLTS-bKi-_ARMXaloLQG7rnGjlKTA/view?usp=sharing"
                rel="noreferrer"
                target="_blank"
              >
                <button>Request Access</button>
              </a>
            </div>
          </div>
        </div>
        <div className="btext">
          <h2 className={style2}>CRAFTING EXPERIENCE FOR AIRTEL REWARDS</h2>
        </div>
        <div className={style5}>
          <h2 className="number">01</h2>
        </div>
      </div>
      <div className="box1 b3" data-aos="fade-up" data-aos-delay="500">
        <div>
          <Tilt style={{ width: "100%" }} options={{ scale: 2, max: 15 }}>
            <a href={PayUCaseStudy} rel="noreferrer" target="_blank">
              <div width="100%">
                <img src={PayuImg} alt="PayU Project"/>
              </div>
            </a>
          </Tilt>
          <Waypoint onEnter={timer2} onLeave={end2} />
          <div className={style2}>
            <h2 className="number2">02</h2>
          </div>
        </div>
        <div className="btext">
          <h2 className={style2}>PAYU NEXTGEN MERCHANTS</h2>
        </div>
      </div>
    </div>
  );
};
export default EndToEndSol;
