import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import { Waypoint } from "react-waypoint";

const Countdown = () => {
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [freelancerYearsCount, setfreelancerYearsCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [style, setStyle] = useState("k");

  const timer = () => {
    setCounter(0);
    setStyle("k");
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
    const timer =
      counter < 60 &&
      setInterval(() => {
        setCounter(counter + 1);
        let i = counter;
        let j = Math.floor(i / 13);
        let k = Math.floor((i * 7) / 26);
        let l = Math.floor((i * 3) / 26);
        if (j >= 2) {
          setStyle("animated9");
          setCount(2);
        } else {
          setCount(j);
        }
        if (l >= 3) {
          setfreelancerYearsCount(3);
        } else {
          setfreelancerYearsCount(l);
        }

        if (k > 7) {
          setCount2(7);
        } else {
          setCount2(k);
        }
        if (i >= 26) {
          setCount3(26);
        } else {
          setCount3(i);
        }
      }, 150);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div>
      <div className=" count">
        <div className="row ">
          <div className="col countext" data-aos="fade-up" data-aos-delay="500">
            <Waypoint onEnter={timer} />
            <h1>0{freelancerYearsCount}</h1>
            <p className={style}>years as a freelancer</p>
          </div>
          <div className="col countext" data-aos="fade-up" data-aos-delay="500">
            <h1>0{count}</h1>
            <p className={style}>Academic Research Projects</p>
          </div>
          <div className="col countext" data-aos="fade-up" data-aos-delay="500">
            <h1>0{count}</h1>
            <p className={style}>Degrees</p>
          </div>
        </div>

        <div className="row ">
          <div className="col countext" data-aos="fade-up" data-aos-delay="600">
            <h1>0{count2}</h1>
            <p className={style}>Awards Won</p>
          </div>
          <div className="col countext" data-aos="fade-up" data-aos-delay="600">
            <h1>0{count2}</h1>
            <p className={style}>years as of experience</p>
          </div>
          <div className="col countext" data-aos="fade-up" data-aos-delay="600">
            <h1>
              {count3 < 10 ? "0" : ""}
              {count3}
            </h1>
            <p className={style}>years lived</p>
          </div>
        </div>
      </div>

      <div className=" count2">
        <Waypoint onEnter={timer} onLeave={timer} />

        <div className="row ">
          <div className="col countext" data-aos="fade-up" data-aos-delay="500">
            <h1>0{freelancerYearsCount}</h1>
            <p className={style}>years as a freelancer</p>
          </div>
          <div className="col countext" data-aos="fade-up" data-aos-delay="500">
            <h1>0{count}</h1>
            <p className={style}>Academic Research Projects</p>
          </div>
        </div>

        <div className="row ">
          <div className="col countext" data-aos="fade-up" data-aos-delay="500">
            <h1>0{count}</h1>
            <p className={style}>Degrees</p>
          </div>

          <div className="col countext" data-aos="fade-up" data-aos-delay="600">
            <h1>0{count2}</h1>
            <p className={style}>Awards Won</p>
          </div>
        </div>

        <div className="row ">
          <div className="col countext" data-aos="fade-up" data-aos-delay="600">
            <h1>0{count2}</h1>
            <p className={style}>years as of experience</p>
          </div>
          <div className="col countext" data-aos="fade-up" data-aos-delay="600">
            <h1>
              {count3 < 10 ? "0" : ""}
              {count3}
            </h1>
            <p className={style}>years lived</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Countdown2 = () => {
  return (
    <div>
      <Waypoint />
      <Countdown />
    </div>
  );
};

export default Countdown2;
