import React, { useEffect, useState } from "react";
import Tilt from "react-vanilla-tilt";
import FocusTrustImg from "../images/research_focus_trust_satis.png";
import PrivacyTrustImg from "../images/Privacy-trust2.png";
import AirtelImg from "../images/research_focus_airtel.png";
import CrossCulturalResearch from "./file/cross_cultural_research.pdf";
import { Waypoint } from "react-waypoint";
import "aos/dist/aos.css";
import Aos from "aos";

const ResearchFocused = () => {
  const [style, setStyle] = useState("k");
  const [style2, setStyle2] = useState("k");
  const [style3, setStyle3] = useState("k");
  const timer = () => {
    setStyle("animated");
  };
  const end = () => {
    setStyle("animated2");
  };
  const timer2 = () => {
    setStyle2("animated");
  };
  const end2 = () => {
    setStyle2("animated2");
  };
  const timer3 = () => {
    setStyle3("animated");
  };
  const end3 = () => {
    setStyle3("animated2");
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  
  return (
    <div className="containner">
      <div className="box1" data-aos="fade-up" data-aos-delay="500">
        <div>
          <Tilt style={{ width: "100%" }} options={{ scale: 2, max: 15 }}>
            <a
              href="https://www.figma.com/proto/9fR5PMvxeHmnegxFJ94DKs/web-recharge-case-study?page-id=0%3A1&node-id=1%3A9431&viewport=420%2C4394%2C0.26&scaling=contain&starting-point-node-id=1%3A9262"
              rel="noreferrer" target="_blank"
            >
              <div width="100%">
                <img src={AirtelImg} alt="Airtel Project"/>
              </div>
            </a>
          </Tilt>
          <Waypoint onEnter={timer} onLeave={end} />
          <div className={style}>
            <h2 className="number">01</h2>
          </div>
        </div>
        <div className="btext" style={{ display: "flex" }}>
          <h2 className={style}>REDESIGNING AIRTEL RECHARGE EXPERIENCE</h2>
        </div>
      </div>
      <div className="box1 b3" data-aos="fade-up" data-aos-delay="500">
        <div>
          <Tilt style={{ width: "100%" }} options={{ scale: 2, max: 15 }}>
            <a
              href="https://blog.prototypr.io/a-step-away-from-privacy-hoax-d6e29ced0488"
              rel="noreferrer" target="_blank"
            >
              <div width="100%">
                <img src={PrivacyTrustImg} alt="Privacy Trust project"/>
              </div>
            </a>
          </Tilt>
          <Waypoint onEnter={timer2} onLeave={end2} />
          <div className={style2}>
            <h2 className="number2">02</h2>
          </div>
        </div>
        <div className="btext">
          <h2 className={style2}>PRIVACY AND TRUST IN MOBILE APPS</h2>
        </div>
      </div>
      <div className="box1" data-aos="fade-up" data-aos-delay="500">
        <div>
          <Tilt style={{ width: "100%" }} options={{ scale: 2, max: 15 }}>
            <a
              href={CrossCulturalResearch}
              rel="noreferrer" target="_blank"
            >
              <div width="100%">
                <img src={FocusTrustImg} alt="Focus Trust Project"/>
              </div>
            </a>
          </Tilt>
          <Waypoint onEnter={timer3} onLeave={end3} />
          <div className={style3}>
            <h2 className="number3">03</h2>
          </div>
        </div>
        <div className="btext">
          <h2 className={style3}>
            TRUST, SATISFACTION & LOYALTY ACROSS M-COMMERCE APPS
          </h2>
        </div>
      </div>
    </div>
  );
};
export default ResearchFocused;
