import React, { useEffect, useState, useRef } from "react";
import emailjs from "emailjs-com";
import Aos from "aos";
import "aos/dist/aos.css";
import ResearchFocused from "./researchFocused";
import EndToEndSol from "./endToEndSol";
import img from "../images/Contact-me.png";
import gif from "../images/Hero-animation.gif";
import "./style/style.css";
import "./style/styleMidScreens.css";
import About from "./about";
import Countdown from "./countdown";
import img1 from "../images/logo_mark.png";
import "./style/navigation.css";

const Body = () => {
  const [nav, setNav] = useState(0);
  const [modalInputTxt1, setModalInputTxt1] = useState("");
  const [modalInputTxt2, setModalInputTxt2] = useState("");
  const [modelInput1Style, setModalInput1Style] = useState("");
  const [modelInput2Style, setModalInput2Style] = useState("");
  const [inputDivStyle1, setInputDivStyle1] = useState("noInputStyle1");
  const [inputDivStyle2, setInputDivStyle2] = useState("noInputStyle2");
  const [imgClipperStyle, setImgClipperStyle] = useState({});
  const [imgStyle, setImgStyle] = useState({ visibility: "hidden" });
  const ref = useRef(null);

  const formSubmit = () => {
    if (modalInputTxt1 === "") {
      setModalInput1Style("errMsg1");
    }
    if (modalInputTxt2 === "") {
      setModalInput2Style("errMsg2");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (modalInputTxt1 != null && modalInputTxt2 != null) {
      emailjs
        .sendForm(
          "service_mty4gyp",
          "template_mqdw5en",
          e.target,
          "user_TLmvK5IREb4yuPyoXDbAQ"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
      e.target.reset();
    }

    setModalInputTxt1("");
    setModalInputTxt2("");
  };

  const formInput1Update = () => {
    if (modalInputTxt1 !== "") {
      setInputDivStyle1("withInputStyle1");
    } else {
      setInputDivStyle1("noInputStyle1");
    }
  };

  const formInput2Update = () => {
    if (modalInputTxt2 !== "") {
      setInputDivStyle2("withInputStyle2");
    } else {
      setInputDivStyle2("noInputStyle2");
    }
  };

  const updateLandingImgClipper = () => {
    if (ref.current) {
      let imgClipperHt =
        ref.current.offsetHeight - ref.current.offsetHeight * 0.01;
      setImgStyle({ visibility: "visible" });
      setImgClipperStyle({
        height: imgClipperHt + "px",
        overflow: "hidden",
      });
    }
  };

  useEffect(() => {
    if (modalInputTxt1 !== "") {
      setInputDivStyle1("withInputStyle1");
    } else {
      setInputDivStyle1("noInputStyle1");
    }
    if (modalInputTxt2 !== "") {
      setInputDivStyle2("withInputStyle2");
    } else {
      setInputDivStyle2("noInputStyle2");
    }
    Aos.init({ duration: 800 });
  }, [modalInputTxt1, modalInputTxt2]);

  return (
    <div className="background">
      <div className="topicon">
        <a
          href="#top"
          style={{ cursor: "pointer" }}
        >
          <img src={img1} alt="RON"/>
        </a>
      </div>
      <div className="container-one">
        <div className="item-one ">
          <h1>
            <span data-aos="new" data-aos-delay="50">
              👋
            </span>
            <span data-aos="new" data-aos-delay="100">
              H
            </span>
            <span data-aos="new" data-aos-delay="150">
              i
            </span>
            <span data-aos="new" data-aos-delay="200">
              ,{" "}
            </span>
            <span data-aos="new" data-aos-delay="250">
              I
            </span>
            <span data-aos="new" data-aos-delay="300">
              ’
            </span>
            <span data-aos="new" data-aos-delay="350">
              m
            </span>
            <span data-aos="new" data-aos-delay="400">
              {" "}
              R
            </span>
            <span data-aos="new" data-aos-delay="450">
              o
            </span>
            <span data-aos="new" data-aos-delay="500">
              n
            </span>
          </h1>
          <span className="t-text" data-aos="new" data-aos-delay="600">
            I help purpose-driven organizations{" "}
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="600">
            to achieve clarity on product-
          </span>

          <span className="t-text" data-aos="new" data-aos-delay="800">
            problems,clarity on innovative concepts,
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="800">
            {" "}
            and clarity on user needs by{" "}
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="1000">
            delivering clear,hard-hitting insights{" "}
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="1000">
            that drive business goals and{" "}
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="1200">
            {" "}
            meaningful impacts. Also, I design and{" "}
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="1200">
            can animate to make things more{" "}
          </span>
          <span className="t-text" data-aos="new" data-aos-delay="1400">
            {" "}
            exciting!
          </span>
          <div className="bt-n" data-aos="new" data-aos-delay="1500">
            <div className="talkbutton">
              <button data-bs-toggle="modal" data-bs-target="#exampleModal">
                Let’s talk!
              </button>
            </div>

            <a href="#work">
              <button type="button" className="  b2">
                Explore my work
              </button>
            </a>
          </div>
          <div data-aos="new" data-aos-delay="1000">
            <h3 className="contact">Contact</h3>

            <h2 className="c2">
              <a
                style={{ textDecorationLine: "none", color: "#EDEFF3" }}
                href="mailto:ronuxdnr@gmail.com"
              >
                ronuxdnr@gmail.com
              </a>
            </h2>
          </div>
        </div>
        <div className="item-two " data-aos="fade-up" data-aos-duration="1000">
          <div className="imageClipper" style={imgClipperStyle}>
            <img
              onLoad={updateLandingImgClipper}
              src={gif}
              ref={ref}
              style={imgStyle}
              alt="gif"
            />
          </div>
        </div>
      </div>
      {/* secound fase */}
      <div className="container-two" data-aos="new" data-aos-delay="100">
        <h2>
          Most of the time, I’m either designing or conducting research for
          purpose -driven organizations.
        </h2>
      </div>
      <About />
      {/* third fase*/}
      <Countdown />
      {/* fourth fase*/}
      <div className="system" id="work">
        <h1 className="head" data-aos="new" data-aos-delay="200">
          Selected Work
        </h1>
        <p className="dis" data-aos="new" data-aos-delay="200">
          Here’s some of my work, in the case you wanna have a look 👀
        </p>
        <div className="navigation" data-aos="new" data-aos-delay="200">
          <div className="n">
            <button
              className={nav === 0 ? "white" : "black"}
              onClick={() => setNav(0)}
            >
              RESEARCH-FOCUSED
            </button>
          </div>
          <div className="n">
            <button
              className={nav === 1 ? "white" : "black"}
              onClick={() => setNav(1)}
            >
              END-TO-END SOLUTION
            </button>
          </div>
        </div>

        <div className="example-two">
          <header className="example-two-header">
            <nav className="vertical-align-middle scroll">
              <div className="n nav-item">
                <button
                  className={nav === 0 ? "white" : "black"}
                  onClick={() => setNav(0)}
                >
                  RESEARCH-FOCUSED
                </button>
              </div>
              <div className="n nav-item">
                <button
                  className={nav === 1 ? "white" : "black"}
                  onClick={() => setNav(1)}
                >
                  END-TO-END SOLUTION
                </button>
              </div>
            </nav>
          </header>
        </div>

        <hr style={{ color: "white", marginTop: "0vh" }} />
        <div className="frame" data-aos="fade-up" data-aos-delay="200">
          {nav === 0 ? (
            <ResearchFocused />
          ) : (
            <div style={{ diaplay: "none" }}></div>
          )}{" "}
          {nav === 2 ? <EndToEndSol /> : <div style={{ diaplay: "none" }}></div>}
          {nav === 1 ? <EndToEndSol /> : <div style={{ diaplay: "none" }}></div>}
        </div>
      </div>

      {/*-- Modal --*/}
      <div>
        <div
          className="modal fade  "
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            zIndex: "99999999999999",
            background: "rgba(21, 23, 24, 0.6)",
          }}
        >
          <div className="modal-fullscreen modal-dialog">
            <div className="modal-content ">
              <button
                type="button"
                className="btn-close cross"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

              <div className="modal-body">
                <div className="im">
                  <img className="submitimg" src={img} alt="submit"/>
                  <div className="top">
                    <h6>Don’t worry, I’m here to help you!</h6>
                  </div>
                </div>
                <div>
                  <div className="from">
                    <div className="texttop">
                      <h1>Don’t worry, I’m here to help you!</h1>
                      <p>
                        My diverse range of expertise is here to assist you with
                        solving your problems through UX Research, Interface and
                        interaction design, motion design or even end-to-end
                        product design projects!
                      </p>
                    </div>
                    <div className="inputtext">
                      <form onSubmit={sendEmail}>
                        <label className="calllabel">
                          What do you want me to call you?
                        </label>
                        <br />

                        <div className={inputDivStyle1}>
                          <input
                            id={modelInput1Style}
                            onMouseEnter={formInput1Update}
                            onMouseLeave={formInput1Update}
                            onFocus={formInput1Update}
                            placeholder={
                              modelInput1Style === "errMsg1"
                                ? "This is important to be filled up!"
                                : "Tell me your name"
                            }
                            type="text"
                            name="name"
                            value={modalInputTxt1}
                            onChange={(e) => {
                              if (
                                e.target.value !== "" ||
                                modalInputTxt1 !== ""
                              ) {
                                setInputDivStyle1("withInputStyle1");
                              } else {
                                setInputDivStyle1("noInputStyle1");
                              }
                              setModalInputTxt1(e.target.value);
                            }}
                            required
                          />
                        </div>
                        <br />

                        <label className="solvelabel">
                          What do you want me to build/solve?
                        </label>
                        <br />

                        <div className={inputDivStyle2}>
                          <textarea
                            id={modelInput2Style}
                            onMouseEnter={formInput2Update}
                            onMouseLeave={formInput2Update}
                            onFocus={formInput2Update}
                            placeholder={
                              modelInput1Style === "errMsg1"
                                ? "This is important to be filled up!"
                                : "Tell me what you want to solve"
                            }
                            name="message"
                            onChange={(e) => {
                              if (
                                e.target.value !== "" ||
                                modalInputTxt2 !== ""
                              ) {
                                setInputDivStyle2("withInputStyle2");
                              } else {
                                setInputDivStyle2("noInputStyle2");
                              }

                              setModalInputTxt2(e.target.value);
                            }}
                            value={modalInputTxt2}
                            required
                          />
                        </div>

                        {modalInputTxt1 === "" || modalInputTxt2 === "" ? (
                          <div className="submitbutton">
                            <button type="button" onClick={formSubmit}>
                              Send your requirement{" "}
                            </button>
                          </div>
                        ) : (
                          <div className="submitbutton2">
                            <button
                              onClick={formSubmit}
                              type="submit"
                              data-bs-toggle="modal"
                              data-bs-target="#example"
                            >
                              Send your requirement
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="modal fade  "
          id="example"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "99999999999999" }}
        >
          <div className="modal-fullscreen contax  modal-dialog">
            <div className="modal-content ">
              <button
                type="button"
                className="btn-close cross2"
                onClick={() => window.location.reload()}
                data-bs-dismiss="modal"
                data-dismiss="model"
                aria-label="Close"
              ></button>
              <div className="modal-body">
                <div className="ondone">
                  <img className="img" src={img} alt="formImg"/>
                  <h2>I’ll go through your requirements and get back you.</h2>
                  <p>
                    Alternatively, you can reach out to me on{" "}
                    <span className="no">+91-8017697352</span>
                  </p>
                  <div className="submitbutton2">
                    <button
                      type="button"
                      onClick={() => window.location.reload()}
                      data-bs-dismiss="modal"
                    >
                      Back to seeing my work!{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Body;
